custom-input {
    width: 100%;
    padding: 13px 16px;
    border: 1px solid transparent;
    box-shadow: none;
    outline: 0;
    margin-bottom: 15px;
    border-radius: 3px;

    &:focus {
        border: 1px solid var(--black-color);
        box-shadow: none;
    }
}

.label-text {
    font-size: 17px;
    color: var(--light-blue);
    text-align: left;
    margin-bottom: 13px;
    font-weight: 600;
    // font-family: 'Corbert';


}



.input-outline {
    @extend custom-input;
    border: 1px solid var(--input-color);
    color: var(--light-blue);
    padding: 11px 16px;

    &:focus {
        box-shadow: 0 0 0 0.10rem #f1c7ac;
        border: 1px solid var(--light-blue-400);
    }


}

.input-outline-light {
    @extend .input-outline;

    &:focus {
        box-shadow: none;
        border: 1px solid var(--light-blue-400);
    }
}

.form-input {
    @extend custom-input;
    background-color: var(--white);
    color: var(--light-blue);

    &.showed {
        margin-bottom: 25px;

        &:focus {
            outline: 2px solid #f1c7ac;
            border-radius: 5px;

        }
    }

}

.input-set {
    position: relative;

    input {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;

        &:focus {
            background-color: transparent !important;
            box-shadow: 0 0 0 0.10rem #f1c7ac;
            border: 1px solid var(--light-blue-400);
            border-radius: 3px;
            border-top-right-radius: 3px !important;
            border-bottom-right-radius: 3px !important;
        }
    }

    .input-group-text {
        background-color: transparent !important;
        border: 0;
        height: 50px;
        padding: 10px 7px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
}