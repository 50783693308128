.owl-theme .owl-nav [class*=owl-]:hover {
    background: transparent !important;
    color: black !important;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    outline: none;
}

#owl-example-article {

    .item {
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

.owl-next span,
.owl-prev span {
    font-size: 30px !important;
    cursor: pointer;
    outline: none !important;

    &:focus {
        outline: none !important;
    }
}

.owl-nav {
    position: absolute;
    top: 93%;
    transform: translate(-50%);
    left: 50%;
    display: flex;
    align-items: center;
}

.owl-item {
    background-color: transparent;
    color: white;
    text-align: center;

}

.owl-prev {
    float: left;
    font-size: 20px;
    text-transform: uppercase;
    padding: 20px;
    margin-right: 70px;
    margin-top: 0;
}

.owl-next {
    float: right;
    font-size: 20px;
    text-transform: uppercase;
    padding: 20px;
}

.owl-dots {
    counter-reset: slides-num;
    /* Initialize counter. */
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%);
    margin-top: 20px;
    margin-left: 5px;


    &:after {
        content: counter(slides-num);
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        vertical-align: middle;
        padding-left: 10px;
    }
}

.owl-dot {
    display: inline-block;
    counter-increment: slides-num;
    /* Increment counter */
    margin-right: 5px;

    span {
        display: none;
    }

    &.active {

        &:before {
            content: counter(slides-num) " / ";
            /* Use the same counter to get current item. */
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            position: absolute;
            left: 0;
            top: 0;


        }
    }
}