:root {
    --font-heading-scale: 0.75;
}


.banner-heading {
    color: var(--white);
    font-family: 'Corbert';
    font-weight: 800;
    font-size: 78px;
    letter-spacing: 0;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 1rem;
    display: block;
    margin-bottom: 1rem;

    @media screen and (max-width: 992px) {
        font-size: 33px;
    }
}

.sub-title {
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 2.2rem;
    text-transform: uppercase;

    &.theme-color {
        color: var(--golden);
        text-transform: capitalize;
        font-family: 'Corbert';
        font-weight: 600;
        font-size: 1.9rem;
    }

    &.black-color {
        color: var(--black);

    }

    &.sub-text-m {
        @media screen and (max-width : 992px) {
            font-size: 25px;
            font-weight: 600;
        }
    }
}

.section-heading {
    font-weight: 800;
    letter-spacing: 1.14px;
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 50px;

    &.card-heading {
        color: var(--blue-grey);
        text-transform: capitalize;
        font-size: 20px;
        font-family: 'Corbert';
    }

}