// footer css start 
.footer-menu {
    background-color: var(--light-blue-400);
    padding: 85px 0px;
    border-top: 5px solid var(--light-blue-100);


    @media screen and (max-width : 992px) {
        padding: 70px 10px;
    }

    @media screen and (min-width : 1200px) {
        .container {
            max-width: 1300px;
            width: 100%;
        }
    }

    .logo-footer {
        padding-left: 20px;

        @media screen and (max-width:768px) {
            padding-left: 0;
            text-align: center;
        }

        img {
            max-width: 21.6rem;
            width: 100%;
            height: auto;
            margin-bottom: 30px;
        }

        .email {
            padding-left: 5px;

            span {
                font-family: 'Corbert';
                font-weight: 900;
                text-transform: uppercase;
                font-size: 16px;
                color: #7b858c;

                a {
                    color: var(--golden);
                    font-weight: 700;

                }
            }
        }
    }

    .footer-linking {
        transform: translateX(-50px);

        @media screen and (max-width:768px) {
            display: none;
        }

        @media screen and (max-width:992px) {
            transform: translateX(0px);
            margin-top: 20px;
            // text-align: cent;

        }

        .title-footer-link {
            font-size: 16px;
            font-family: 'Corbert';
            font-weight: 800;
            margin-bottom: 0;
        }

        ul {
            padding: 0;
            list-style: none;
            margin-top: 10px;

            li {
                display: block;
                padding: 5px 0;

                a {
                    color: #28323a;
                    text-transform: uppercase;
                    font-size: 15px;
                    font-weight: 500;
                    font-family: 'Corbert';
                    letter-spacing: 1px;
                }
            }
        }


    }
}

.footer__content-bottom {
    background-color: var(--blue-grey);
    padding: 20px 20px;

    .container {
        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    .right-to-bear-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (max-width : 768px) {
            flex-direction: column;
            justify-content: center;
            gap: 20px;
        }

        .copy-right {
            padding-left: 20px;

            @media screen and (max-width:768px) {
                padding-left: 0px;
            }

            span {
                color: var(--white);
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: .92px;
                font-weight: 300;

                a {
                    color: var(--white);

                    &:hover {
                        border-bottom: 1px solid #fff;
                    }
                }
            }
        }

        .bottom-footer-term-condition {
            a {
                color: #ffffff;
                font-size: 14px;
                text-transform: uppercase;
                letter-spacing: .92px;
                font-weight: 300
            }
        }

        .footer__list-social {
            padding-right: 30px;

            ul {
                list-style: none;
                display: flex;
                margin-bottom: 0;
                gap: 23px;

                li {

                    transition: transform 700ms ease 0ms;

                    a {
                        color: var(--white);
                        font-size: 20px;


                    }

                    .fa {
                        &:hover {

                            transform: scaleX(1.05) scaleY(1.05);
                        }

                    }

                    &:hover {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}

// footer css end 