custom-button {
    padding: 18px 70px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    outline: 0;
    border: 0;
    margin: 10px 0;
    box-shadow: none;
    font-size: 18px;
    font-family: "Corbert";
    // font-weight: 600;
    letter-spacing: 1px;
    // text-transform: uppercase;
    cursor: pointer;
}

.button-black {
    @extend custom-button;
    background-color: var(--black);
    background-color: transparent !important;
    color: var(--golden);

    &:hover {
        background-color: var(--golden) !important;
        color: var(--white) !important;
    }

    &.button-black-size {
        @media screen and (max-width:768px) {
            padding: 10px 20px;
            width: 100%;

        }
    }
}

.button-golden {
    @extend custom-button;
    background-color: var(--golden);
    padding: 15px 40px;
    color: var(--white);
    border: 1px solid transparent;

    &:hover {
        background-color: transparent;
        border: 1px solid var(--golden);
        color: var(--golden);
    }

    &.border-golden {
        border: 1px solid var(--golden);
        background-color: transparent;
        color: var(--golden);

        &:hover {
            background-color: var(--golden);

            color: var(--white);
        }

    }

    &.size-font {
        padding: 10px 20px;
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap
    }

    &.fix-mobile-size {
        @media screen and (max-width : 768px) {
            padding: 10px 90px;
        }
    }


}

.button-white {
    @extend custom-button;
    background-color: var(--white);
    box-shadow: 0px 0px 1px #e4e0e0;
    color: var(--golden);
    border: 1px solid transparent;


    &:hover {
        border: 1px solid var(--black);
        color: var(--black);
    }

    &.mobile-button {
        @media screen and (max-width:768px) {
            padding: 10px 40px !important;
        }
    }

    &.mobile-width {
        @media screen and (max-width:568px) {
            width: 100% !important;
            padding: 10px 0 !important;
        }
    }
}

.theme-button {
    @extend custom-button;
    background-color: var(--golden);
    color: var(--white);
    border: 1px solid transparent;
    white-space: nowrap;

    &:hover {
        color: var(--golden);
        border: 1px solid var(--golden);
        background-color: var(--white);
    }

    &.button-nav-account {
        padding: 9px 20px;
        margin: 0;
        color: var(--golden) !important;
        background-color: transparent !important;
        border: 1px solid var(--golden);
        text-transform: capitalize !important;

        &:hover {
            background-color: var(--golden) !important;
            color: var(--white) !important;
        }
    }

    &.mobile-button {
        @media screen and (max-width:768px) {
            width: 100% !important;
            padding: 8px 70px !important;
        }
    }

    &.disable {
        opacity: 0.6;
        pointer-events: none;
    }

    @media screen and (max-width:768px) {
        &.mobile-button-view {
            padding: 11px 45px !important;
        }
    }

    &.border-golden-1 {
        border: 1px solid var(--golden);
        background-color: transparent;
        color: var(--golden);

        &:hover {
            background-color: var(--golden);

            color: var(--white);
        }

    }

    &.button-size {
        padding: 23px 95px;
        font-size: 25px;

        &:hover {
            border: 1px solid var(--golden) !important;
            background-color: var(--white);

        }

        @media screen and (max-width : 768px) {
            padding: 7px 20px !important;
            font-size: 20px;

        }
    }
}

.from-button {
    @extend custom-button;
    background-color: var(--golden);
    border: 1px solid transparent;
    color: var(--white);
    width: 100%;

    &:hover {
        background-color: var(--white);
        color: var(--golden);
        border: 1px solid var(--golden)
    }
}

.button-black {
    @extend custom-button;
    background-color: var(--golden);
    border-radius: 0;
    border: 1px solid var(--golden);
    border-radius: 3px;

    &:hover {
        background-color: var(--white);
        color: var(--golden);
    }

    &.size-button {
        padding: 12px 20px;
        text-transform: capitalize;
        // font-weight: 600;
        font-size: 14px;
    }

    &.size-border {
        background-color: transparent;
        border: 1px solid var(--golden);
        color: var(--golden);
        padding: 12px 35px;
        // font-weight: 600;

        &:hover {
            background-color: var(--golden);
            color: var(--white);
        }
    }
}