.navbar {

    border-top: 5px solid var(--golden);
    z-index: 999 !important;
    width: 100%;
    background-color: var(--white);
    height: 120px;
    border-bottom: 1px solid #bdb8b4 !important;

    padding-top: 0;

    .container {
        @media screen and (min-width: 1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 1.4rem;

        .logo {
            img {
                width: 8.7rem;

            }
        }

        nav {
            margin-left: auto;

            .menu-bar {
                display: flex;
                // justify-content: space-between;
                padding: 0;
                padding-right: 30px;
                margin-bottom: 0;

                li {
                    list-style: none;
                    display: block;
                    padding: 0 37px;

                    @media screen and (max-width : 1199px) {
                        padding: 0 10px;
                    }

                    a {
                        text-decoration: none;
                        font-size: 16px;
                        text-transform: uppercase;
                        letter-spacing: 1.25px;
                        font-weight: 600;
                        text-decoration: unset;
                        color: var(--black-color);
                        font-family: 'Corbert';
                        text-align: right;

                        &:hover {
                            color: var(--golden);
                        }

                        &:focus {
                            color: var(--golden);
                        }
                    }

                    &.active {
                        color: var(--golden);
                    }
                }

                &.account-menu {
                    align-items: center;
                    // border-left: 1px solid gainsboro;
                    padding-right: 110px;
                }
            }
        }

        .log-in {

            a {
                text-decoration: none;
                font-size: 16px;
                border-left: 1px solid gainsboro;
                color: var(--black);
                padding: 15px 0 10px 80px;
                display: block;
                font-weight: 700;
                font-family: 'Corbert';

                &:hover {
                    color: var(--golden);

                }

                &.active {
                    color: var(--golden);
                }
            }


        }
    }

}

.account_log_in {
    width: 40px;
    aspect-ratio: 1/1;
    background-color: var(--golden);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;

    .fa {
        color: var(--white);
        font-size: 20px;


    }

    &:hover .fa {
        transform: scaleX(1.05) scaleY(1.05);
    }

    a {
        border-left: 1px solid transparent;
    }
}

// mobile menu css

.navbar-mobile-menu {
    // background-color: var(--white);
    // border-top: 5px solid var(--golden);
    // position: sticky;
    // top: 0;
    // left: 0;
    // z-index: 99;

    header {
        padding: 15px 30px;

        @media screen and (max-width : 992px) {
            padding: 23px 5px;
        }

        @media screen and (max-width : 542px) {
            padding: 30px 5px;
        }

        .logo {
            img {
                width: 150px;

                @media screen and (max-width:768px) {
                    width: 125px;

                }
            }
        }

        #click {
            display: none;
        }

        #click:checked~.menu-btn i::before {
            content: "\f00d";

        }

        .menu-btn {
            position: absolute;
            right: 40px;
            top: 37px;
            font-size: 30px;

            @media screen and (max-width:768px) {
                right: 20px;

            }
        }

        nav {

            .menu-bar {
                padding: 0;
                display: block !important;

                li:first-child {
                    border-top: 0px;
                }

                li {
                    border-top: 1px solid gainsboro;
                    padding: 7px 0;
                    display: block;


                    &:hover {
                        background-color: #1212120A;
                        color: #000;

                    }

                    a {
                        padding: 5px 20px;
                        display: block;
                        text-align: left;

                        &:hover {
                            color: #000;

                        }
                    }

                    &.active {
                        color: var(--golden);
                    }
                }

                @media screen and (max-width : 992px) {
                    position: fixed;
                    top: 120px;
                    left: 0;
                    width: 100%;
                    height: 100vh;
                    background-color: var(--white);
                    transform: translateX(-100%);
                    transition: all 0.5s ease-in-out;
                    overflow-y: auto !important;

                }
            }


        }

        #click:checked~.menu-bar {
            transform: translateX(0);
        }

    }

}