@import './components/';
@import './outercss/';

a {
    text-decoration: none;
}

body {
    font-family: 'Assistant';
}

.banner {
    background-image: url('../../public/images/home-hero.webp');
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 102px 70px;

    @media screen and (max-width:768px) {
        padding: 42px 10px;
    }

    .banner-outre {
        max-width: 650px;
        width: 100%;
        // padding-left: 80px;
        margin-bottom: 30px;

        @media screen and (max-width:768px) {
            margin-bottom: 0px;
        }

        @media screen and (max-width: 992px) {
            padding-left: 0px;
            text-align: center;
        }

        .outer-title {
            font-size: 25px;
            color: var(--white);
            font-family: 'Assistant';
            margin-bottom: 32px;
            font-weight: 500;
        }
    }


}

.video-frame {
    margin: 20px 0px 40px;
    padding: 0 40px;
    margin: 0 auto;

    @media screen and (max-width : 992px) {
        padding: 0;
    }

    .container {
        @media screen and (min-width: 1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }
}

.responsibly {
    padding-left: 40px;
    margin: 160px 0;
    // font-family: 'dashiellfine-medium';

    @media screen and (max-width :1192px) {
        padding-left: 0px;
        margin: 60px 0;

    }

    .responsibly-user-text {
        // padding: 0 20px;
        position: relative;
        z-index: 0;
        padding-left: 20px;

        @media screen and (max-width : 992px) {
            padding-left: 0px;
            padding: 0 15px;
        }

        @media screen and (max-width : 568px) {

            padding: 0;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 70px;
            background-color: var(--white);
            z-index: -1;

            @media screen and (max-width : 1192px) {
                left: 0;

            }
        }

        .sub-text-user {
            font-size: 21px;
            font-weight: 500;
            word-spacing: 1px;
            color: var(--grey);
        }

        .list-pointer {
            font-family: 'dashiellfine-medium';


        }


    }

    .gun-images {
        border-top: 1px solid var(--light-blue-400);
        border-bottom: 1px solid var(--light-blue-400);
    }

    .font-change {
        padding-top: 15px;
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 20px;
        font-family: 'dashiellfine-medium';


    }

    .blog-list-heading {
        font-weight: 600;
        font-size: 32px;
        font-family: 'dashiellfine-medium';
        line-height: 42px;
        margin-bottom: 20px;

        @media screen and (max-width:992px) {
            font-size: 22px;
            line-height: 32px;

        }
    }
}

.list-pointer {
    ul {
        list-style: none;
        padding: 0;

        li {
            display: flex;
            align-items: flex-start;
            padding: 10px 0;

            img {
                width: 12px;
                padding-top: 5px;
            }

            p {
                font-weight: 600;
                color: var(--golden);
                font-size: 22px;
                letter-spacing: 0.38px;
                font-family: "dashiellfine-medium";
                margin-bottom: 0;
                padding-left: 10px;
            }
        }
    }

    .list-available {
        color: var(--grey);

        @media screen and (max-width:768px) {
            text-align: center;
        }
    }
}

.shopify-section-template {
    background-image: url('../../public/images/cta-footerjpg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    padding: 30px 0;
    width: 100%;
    height: 100%;

    .template {
        max-width: 720px;
        width: 100%;
        margin: auto;
    }

    .shopify {
        text-align: center;

        .section-heading {
            color: var(--white);
            margin-bottom: 30px;
            font-size: 46px;
        }

        .email-text {
            font-size: 19px;
            font-weight: 500;
            color: var(--white);
            letter-spacing: 1px;
            font-family: 'Corbert';

            a {
                color: var(--white);
            }
        }

        .check-img-and-text {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            img {
                margin-top: 8px;
            }

            p {
                margin-bottom: 0;
                font-size: 18px;
                color: var(--black);
            }
        }
    }

    .form-floating {
        .form-control {
            height: 46px;
            border-radius: 5px;
        }

        label {
            position: absolute;
            padding: 0.7rem 0.75rem;
            font-size: 16px;
            color: #adb0b3;
        }
    }


    .form-text-last {
        font-size: 13px;
        text-align: center;
        color: var(--white);
        font-weight: 500;
    }

}

.form-floating {
    .form-control {
        height: 46px;
        border-radius: 5px;
    }

    label {
        position: absolute;
        padding: 0.7rem .75rem;
        font-size: 15px;
        color: grey;
    }
}

// about css start

.about-banner {
    background-image: url('../../public/images/about-banner.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 125px 0;
    width: 100%;
    height: 100%;

    @media screen and (max-width : 768px) {
        padding: 80px 10px;
    }

    .about-outer {
        .banner-heading {
            max-width: 660px;
            margin: auto;
            text-align: center;
        }
    }
}

.liberty-outer {
    padding: 50px 40px;
    text-align: center;
    // max-width: 1100px;
    // width: 100%;
    // margin: auto;


    @media screen and (max-width : 992px) {
        padding: 50px 0px 0;
    }

    .container {
        @media screen and (min-width : 1200px) {
            max-width: 1340px;
        }
    }

    .liberty-text {
        max-width: 1100px;
        margin: 0 auto;
    }

    .title-text {
        font-size: 19px;
        color: var(--golden);
        font-family: 'Assistant';
        font-weight: 800;
        letter-spacing: 1.2px;
    }

    .sub-title-2 {
        font-size: 30px;
        font-weight: 600;
        line-height: 30px;
        font-family: 'dashiellfine-medium';
        word-spacing: 2px;
        letter-spacing: 1px;
    }

    .bear-text {
        font-size: 19px;
        color: var(--grey);
        line-height: 30px;
    }

    .sub-title-list {
        font-size: 19px;
        color: var(--black);
        font-family: 'dashiellfine-medium';
        font-weight: 600;
        margin-top: 20px;
        padding: 0 70px;
        word-spacing: 2px;

        @media screen and (max-width :992px) {
            padding: 0 14px;
        }
    }

    .right-to-bear-text {
        font-size: 17px;
        font-family: 'Corbert';
        font-weight: 600;
        color: var(--grey);
    }

    .percentage {
        margin: 70px 0 20px;
    }

    .right-status {
        padding: 50px 0px;
        text-align: left;

        @media screen and (max-width:768px) {
            padding: 50px 0 0;
        }


        .status-right-bear {


            h4 {
                font-size: 17px;
                font-weight: 700;
            }

            p {
                font-size: 17px;
                color: var(--grey);
                line-height: 25px;
            }
        }

        .join-btn {
            text-align: center;
        }

    }

}

.responsible-user-gun {
    margin: 50px 0 30px 0;

    .user-gun-text {
        padding: 38px 80px;

        @media screen and (max-width : 992px) {
            padding: 20px 30px;
        }

        p {
            font-size: 20px;
            line-height: 35px;
            color: var(--grey);

        }
    }

    .button-white {

        background-color: var(--golden);
        color: var(--white);

        &:hover {
            border: 1px solid var(--golden);
            background-color: var(--white);
            color: var(--golden);
        }
    }

    .gun-man {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .rifle-percentage {
            padding-left: 10px;

            @media screen and (max-width : 992px) {
                padding: 0;
            }
        }
    }
}

// about css end

// education css start
.banner-self {
    background-image: url('../../public/images/Screenshot.png');
}

.bear-facts {
    text-align: center;
    margin: 70px 0 20px;
    padding: 0 50px;


    @media screen and (max-width :992px) {
        padding: 0;
    }

    @media screen and (min-width:1200px) {
        .container {
            max-width: 1340px;
            width: 100%;
        }
    }

    .facts-bear-heading {
        padding: 0 20px;
        margin-bottom: 50px;

        @media screen and (max-width :992px) {
            padding: 0;
        }

        img {
            max-width: 220px;
            width: 100%;

            @media screen and (max-width :768px) {
                margin: 25px 0 20px;
                max-width: 140px;
            }
        }

        h4 {
            font-size: 15px;
            margin-bottom: 0;
            padding: 20px 0 10px;
            font-weight: 600;
            word-spacing: 2px;
            letter-spacing: 1px;
            color: var(--black);
            font-family: 'Condensed';

            @media screen and (max-width :768px) {
                padding: 0 0 10px;
            }
        }

        .sub-hea-edc {
            font-size: 18px;
        }

        p {
            font-size: 16px;
            margin-bottom: 0;
            line-height: 30px;
            color: var(--grey);
            font-family: 'Corbert';
        }
    }
}

.post-blog {
    padding: 0 30px;
    margin-bottom: 70px;

    .container {
        @media screen and (min-width :1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    @media screen and (max-width :992px) {
        padding: 0;
    }

    &.card-disable {
        @media screen and (max-width:992px) {
            display: none;
        }
    }

    &.theme-carousel {
        display: none;

        @media screen and (max-width:992px) {
            display: block;
        }
    }

    .blog-view {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        a {
            font-size: 15px;
            text-decoration: underline;
            color: #000;
            display: none;

            @media screen and (max-width:768px) {
                display: block;
            }
        }
    }

    .blog-heading {
        font-weight: 800;
    }


}

.outer-card {
    color: var(--black);

    &:hover {
        color: var(--black);
    }

    .card {

        border-radius: 0;
        border: transparent;

        .blog-image {
            overflow: hidden;

            img {
                width: 100%;
                height: 250px;
                object-fit: cover;

                @media screen and (max-width:768px) {
                    // height: 100%;
                }
            }
        }

        .card-body {
            padding: 16px 0;

            .card-title {
                font-size: 20px;
                font-weight: 600;
            }

            .card-text {
                font-size: 13px;
                color: var(--grey);
            }

            .card-sub-title {
                font-size: 18px;
                line-height: 27px;
                color: var(--grey);

                @media screen and (max-width : 992px) {
                    margin-bottom: 40px;
                }
            }
        }

        transform: scale(1);
        overflow: hidden;
        transition: all 0.5s ease-in-out;

        &:hover img {
            transform: scale(1.1);
            transition: all 0.5s ease-in-out;
            overflow: hidden;
        }

        &:hover .card-title {
            text-decoration: underline;
        }
    }

    .card-body {
        @media screen and (max-width : 992px) {
            text-align: left;
            padding: 15px 0px;
        }
    }
}

.join-right {
    margin: 0 0 30px;

    .join-file {
        padding: 30px 90px 30px 65px;

        @media screen and (max-width : 992px) {
            padding: 40px;
        }

        .list-pointer {


            .text-liability {
                color: var(--grey);
                font-weight: 600;
                font-size: 21px;
                line-height: 40px;
                font-family: 'Corbert';
            }

        }
    }

    .istock-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 55% center;
    }
}

.line {
    p {
        line-height: 20px !important;
    }
}

.list-policy {
    display: flex;

    .list-image {
        transform: translateY(-15px);
    }

    img {
        width: 12px;
        margin-right: 10px;
    }

    p {
        font-weight: 700;
        color: var(--golden);
        font-size: 19px;
        letter-spacing: 0.38px;
        font-family: 'dashiellfine-medium';
        margin-bottom: 0;
        padding: 10px 0;
    }
}

// education css end


// coverage css start

.select-user-plan {
    text-align: center;
    padding: 70px 0 30px;
    // border-top: 1px solid #bdb8b4;
    // margin-top: 13px;

    @media screen and (max-width : 768px) {
        padding: 50px 0 0;
    }

    .select-heading {
        font-size: 18px;
        font-weight: 600;
        color: var(--golden);
        letter-spacing: 1px;
        padding-bottom: 25px;
    }

    .sub-title {
        font-family: 'dashiellfine-medium';
        text-transform: capitalize;
        font-size: 30px;

        @media screen and (max-width : 768px) {
            font-size: 25px;
        }
    }
}

.coverage {
    font-family: 'dashiellfine-medium';
    margin-bottom: 10px;
    padding-top: 20px;

    .coverage-police {
        padding-left: 5px;
        margin-bottom: 100px;

        @media screen and (max-width :992px) {
            padding-left: 0;
        }

        .sub-title {
            font-size: 28px;
            padding-bottom: 15px;
        }

        .cover-ages-text {
            border-left: 7px solid var(--golden);


            p {
                font-size: 18px;
                color: var(--golden);
                font-weight: 600;
                // padding-left: 10px;
                font-family: 'Corbert';
                padding: 5px 10px;
            }
        }
    }

}

// coverage css end


// events css start

.event {
    background-image: url('../../public/images/events.png');
    text-align: center;
    position: relative;
    z-index: -1;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(14, 1, 1, 0.233);
    }

    .banner-heading {
        position: relative;
        z-index: 1;
    }

    p {
        font-size: 20px;
        font-weight: 500;
        color: var(--white);
        position: relative;
        z-index: 1;
        padding-top: 37px;
    }
}

.event-card-list {

    margin: 0 auto 10px;
    padding: 0 40px;

    @media screen and (max-width : 992px) {
        padding: 0 0px;
    }

    .event-user-man {
        max-width: 1080px;
        width: 100%;
        margin: 0 auto;
    }

    @media screen and (max-width :992px) {
        max-width: 1140px;
    }

    .container {
        max-width: 1140px;
        width: 100%;

        @media screen and (min-width : 1299px) {
            max-width: 1320px;
            width: 100%;
        }
    }


    .event-heading {
        text-align: center;
        max-width: 800px;
        width: 100%;
        margin: 40px auto 80px;

        @media screen and (max-width:992px) {
            margin: 20px 0;
            max-width: 100%;
        }

        h4 {
            font-size: 20px;
            color: var(--golden);
            font-weight: 600;
            padding: 0px 0 30px;
        }

        p {
            font-size: 17px;
            color: #1a2a3f;
            line-height: 27px;
            font-weight: 500;
        }
    }

    .event-mane-heading {
        font-weight: 600;
        margin-bottom: 20px;
        padding-left: 12px;

        @media screen and (max-width : 992px) {
            padding-left: 0;
        }
    }

    .event-card {
        width: 100%;
        height: 100%;
        padding: 0 0px 8px;

        .card {
            padding: 20px;
            background-color: #f3f3f3;
            border: transparent;
            border-radius: 0;
            width: 100%;
            height: 100%;

            img {
                min-height: 220px;
                max-height: 300px;
                width: 100%;
                object-fit: cover;
            }


            .card-body {
                padding: 0;
                display: flex;
                flex-direction: column;

                .card-heading {
                    font-size: 17px;
                    color: var(--black);
                    padding-top: 20px;
                    font-weight: 600;
                    letter-spacing: 1px;
                }

                .card-titles {
                    font-size: 18px;
                    color: var(--grey);
                    margin-bottom: 0;
                }

                .card-text {
                    font-size: 16px;
                    line-height: 30px;
                    color: var(--grey);
                }

                a {
                    font-size: 18px;
                    color: #0d6efd;
                    cursor: pointer;
                    margin-top: auto;
                    font-weight: 300;
                    display: flex;
                    align-items: center;
                    column-gap: 6px;
                    max-width: 120px;
                    width: 100%;

                    .fa {
                        margin-top: 5px;
                    }

                    &:hover .fa {
                        padding-left: 2px;
                        transition: all 0.5s ease-in-out;
                    }
                }
            }
        }
    }
}

// events css end

// faq page css start

.faq-banner {
    background-image: url('../../public/images/faq_hero.webp');
}

.faq-question {
    // max-width: 1100px;
    // width: 100%;
    margin: 100px auto 40px;
    padding: 0 40px;

    @media screen and (max-width:992px) {
        margin: 50px auto 40px;
        padding: 0 0px;
    }

    .container {
        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    .accordion-item {
        border: 0;

        .accordion-button {
            font-size: 17px;
            color: var(--golden);
            font-weight: 600;
            padding: 14px 10px;
            background-color: transparent;
            border-radius: 0;
            border-top: 1px solid var(--light-blue-400);
            position: relative;
            box-shadow: none;


            &:focus {
                box-shadow: 0 0 0 0.15rem #b6632f40;
                z-index: 0;
                position: relative;
            }
        }



        .button-faq {
            border-bottom: 1px solid transparent !important;
        }

        .accordion-body {
            padding: 0px 10px 0px 30px;
            border-top: 1px solid var(--light-blue-400);

            @media screen and (max-width : 992px) {
                padding: 0 10px;
            }

            .body-title {
                font-size: 18px;
                color: var(--blue-grey);
                font-weight: 600;
                // border-top: 1px solid var(--light-blue-400);
                margin-bottom: 0;
                padding: 14px 0;
            }

            .body-title-list {

                padding: 14px 0 0;

            }

            .font-list-civil {

                padding: 14px 0;

                p {
                    font-size: 0.9rem;
                    margin-bottom: 0;
                    font-family: 'Assistant';


                }
            }

            .emergency-title {
                padding: 14px 0;

                ol {


                    li {
                        padding: 3px 0;
                        font-size: 17px;
                        color: var(--grey);
                        font-family: 'Corbert';
                    }
                }
            }

            .body-title-sub {

                padding: 14px 0;

                p {
                    font-size: 16px;
                    margin-bottom: 0;


                    span {
                        font-size: 16px;
                        font-weight: 600;
                        color: #504e4e;
                    }
                }

                ol {
                    padding: 14px 10px;

                    li {
                        line-height: 30px;
                        font-size: 16px;
                        font-family: 'Assistant';
                        color: var(--black);

                        span {
                            font-weight: 600;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }


        .accordion-button:not(.collapsed)::after {
            background-image: url('../../public/images/minus.svg');
        }

        .accordion-button::after {
            background-image: url('../../public/images/plus.svg');
        }
    }
}

// faq page css end

// join form css start

.submit {
    max-width: 705px;
    width: 100%;
    padding: 100px 0;
    margin: 0 auto;


    @media screen and (max-width : 992px) {
        padding: 100px 20px;
    }

    h3 {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        word-spacing: 4px;
    }

    .account {
        text-align: center;
        font-size: 17px;
        color: var(--blue-grey);
        padding-top: 20px;
        font-weight: 500;

        a {
            color: var(--golden);
        }
    }
}

// join form css end

.table {
    margin-bottom: 0;
}

.tab-list-user {
    // border-top: 1px solid #bdb8b4;
    padding: 30px 20px 0;
    // margin-top: 13px;

    @media screen and (max-width : 768px) {
        padding: 30px 0 0;
    }

    .nav-tabs {
        border: 1px solid transparent;
        align-items: center;
        padding-left: 22px;

        @media screen and (max-width : 512px) {
            padding-left: 10px;
        }

        a {
            padding-right: 6px;
            color: #b6632f;

            .fa {
                transform: translateX(7px);
                color: #7b858c;
            }
        }

        .nav-item {


            .nav-link {
                background-color: transparent;
                border: 1px solid transparent;
                color: #B6632F;
                font-size: 14px;
                padding: 8px 10px;

                &.color-gray {
                    color: #7b858c;
                }

                .fa {
                    transform: translateX(9px);
                    color: #7b858c;
                }

                &:hover {
                    border: 1px solid transparent;
                }

                &.active {
                    color: #b6632f;
                }
            }
        }
    }
}

.join-form-user {
    padding: 0 15px;
    margin: 10px auto 30px;

    @media screen and (max-width : 512px) {
        padding: 0 0px;
    }

    // .container {
    //     @media screen and (min-width : 1200px) {
    //         max-width: 1340px;
    //         width: 100%;

    //     }
    // }

    .outer-event-policy {
        border: 1px solid #cacdcf;
        padding: 32px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        @media screen and (max-width :992px) {
            flex-direction: column;
            justify-content: center;
            text-align: center;
            row-gap: 20px;
        }

        h4 {
            font-size: 18px;
            color: var(--blue-grey);
            font-weight: 600;
            margin-bottom: 0;
            line-height: 30px;
        }


    }


    .details-user-form {
        // margin-bottom: 30px;
    }

    .billing-plan {
        font-size: 12px;
        // margin-bottom: 50px;
        margin-left: 10px;
        letter-spacing: 3px;
        color: rgb(165, 156, 156);

        @media screen and (max-width : 992px) {
            margin: 0;
        }
    }

    .bill-address {
        font-size: 14px;
        letter-spacing: 2px;
        color: rgb(165, 156, 156);
    }

    .page-link-all {
        display: flex;
        gap: 10px;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 20px;

        a {
            font-size: 15px;
            color: var(--grey);

            .fa {
                margin-left: 10px;
                color: var(--grey);
            }


            &.active {
                color: var(--golden);
            }
        }
    }

    .put-filed-same {
        display: flex;
        align-items: center;

        label {
            font-size: 17px;
            font-weight: 400;
            margin-right: 10px;
            color: rgb(172, 162, 162);
        }

        input {
            width: 23px;
            height: 23px;
            border-radius: 3px !important;

        }
    }

    .order-summary {
        border: 1px solid var(--light-blue-400);

        // margin-top: 22px;
        @media screen and (max-width : 992px) {
            margin-top: 22px;
        }

        .order-heading {
            background-color: var(--golden);
            text-align: center;

            h3 {
                font-size: 19px;
                color: var(--white);
                font-weight: 600;
                padding: 22px 0;
                letter-spacing: 2px;
            }
        }

        .cover-yearly {
            padding: 8px 29px;
            border-bottom: 1px solid var(--light-blue-400);
            text-align: center;

            h4 {
                font-size: 20px;
                font-weight: 600;
                color: var(--blue-grey);
                margin-bottom: 0;
            }

            p {
                font-size: 14px;
                color: var(--grey);
                font-weight: 600;
                margin-bottom: 0;
            }
        }


    }

    .items-plan {
        margin-bottom: 0;
        padding: 0;


        tbody {
            font-family: 'Assistant';
            background-color: var(--white);

            tr {
                border: 1px solid transparent;
                border-bottom-width: 0 !important;
                padding: 0;
                border-style: none !important;

                td {
                    padding: 5px;
                }
            }
        }

        .plan {
            font-size: 15px;
            margin-bottom: 0;
            padding-left: 4px;
            font-weight: 500;
            color: var(--grey);
        }

        .price {
            font-size: 18px;
            font-weight: 600;
            text-align: right;
            padding-right: 4px;
            margin-bottom: 0;
        }

        .price1 {
            @extend .plan;
            text-align: right;
            padding-right: 4px;
        }

        .total {
            padding: 10px;
            font-size: 15px;
            color: var(--blue-grey);
            font-weight: 600;
            letter-spacing: 1px;
            margin-bottom: 0;
        }

        .all-total {
            font-size: 20px;
            text-align: right;
            padding-right: 4px;
            font-weight: 600;
            padding: 10px 4px;
            margin-bottom: 0;
        }

        .last-total {
            border-top: 1px solid var(--light-blue-400) !important;
            padding: 10px;
            background-color: var(--white);
        }
    }





}

.form-check {
    .form-check-input {
        border-radius: 3px;

        &:focus {
            box-shadow: none;
            border: 1px solid var(--light-blue-400);
            border-radius: 3px;
        }
    }

    .form-check-input:checked {
        background-color: var(--golden);
        border-radius: 3px;
        border: 0px;
    }
}

.user-all-id {
    // padding-top: 40px;
    padding-bottom: 40px;
    overflow: auto;
    position: relative;
    margin-top: 55px;

    .user-tab {
        display: flex;
        column-gap: 30px;

        @media screen and (max-width : 1092px) {
            flex-direction: column;
            row-gap: 30px;
        }

        .m-d {
            background-color: var(--light-blue-400);
            padding: 20px;
            flex: 1 0 430px;

            @media screen and (max-width : 992px) {
                flex: 1;
            }

            h3 {
                border-bottom: 1px solid var(--light-blue-100);
                font-size: 17px;
                padding-bottom: 15px;
                font-weight: 600;
                color: var(--blue-grey);
            }

            p {
                font-size: 17px;
                font-weight: 500;
                line-height: 25px;
                color: var(--blue-new-color);
                line-height: 30px;
                margin-bottom: 30px;

                .user-name-b {
                    font-weight: 600;
                    color: var(--black);
                    font-size: 16px;
                }
            }
        }
    }

    .table {

        margin-bottom: 0;
        width: 100%;
        transform: translateY(-19px);

        @media screen and (max-width : 992px) {
            transform: translateY(0px);
        }

        .table-user-line {
            border-top: 1px solid transparent;
        }

        tr {
            @media screen and (max-width : 992px) {

                flex-direction: row !important;
                align-items: center;
                justify-content: space-between;
            }

            th {
                border-color: rgba(197, 197, 197, 0.534) !important;
                padding-left: 50px;
            }

            td {
                border-color: rgba(197, 197, 197, 0.534);
            }
        }

        .user-name {
            // padding-right: 100px;
            margin-bottom: 0;
            // transform: translateY(-110px);
            font-size: 18px;
            font-weight: 600;
            color: var(--blue-grey);
            // position: absolute;
            // top: 0;

            // @media screen and (max-width : 992px) {
            //     margin-right: 100px;
            // }
        }

        .full-address-user {
            p {
                margin-bottom: 0;
                font-size: 17px;
                font-weight: 400;
                line-height: 25px;
                color: #7b858c;
                line-height: 30px;

                span {
                    font-size: 15px;
                    font-weight: 600;
                    color: #323e48;
                    font-family: 'Corbert';
                }

            }

            margin-bottom: 30px;

            @media screen and (max-width: 992px) {
                margin-left: 0px;
            }
        }

        .table-date-title {
            font-size: 17px;
            font-weight: 600;
            color: #323e48;
            margin-bottom: 0;
            padding: 17px 0;
            width: 250px;
            white-space: nowrap;

            @media screen and (max-width:992px) {
                width: 100px;
            }
        }

        .yearly-list-user {
            font-size: 16px;
            color: #323e48;
            font-weight: 600;
            padding: 17px 0 17px 45px;
            margin-bottom: 0;
        }

        .table-date-title-add {
            @extend .table-date-title;
            padding: 30px 0;
        }

        .bond {
            list-style: none;
            padding: 0;
            width: 100%;
            padding-left: 44px;

            .yearly-list-user-add {
                font-size: 16px;
                color: #323E48;
                font-weight: 500;
                padding: 0px 20px 0 0;
                margin-bottom: 0;
                flex: 1;
                display: flex;
                justify-content: space-between;
            }
        }




        .yearly-list-user-add {
            font-size: 16px;
            color: #323e48;
            font-weight: 500;
            padding: 30px 0;
            margin-bottom: 0;
        }

        .dollar {
            font-size: 17px;
            color: var(--black);
            padding: 17px 0;
            margin-bottom: 0;
            text-align: right;
            padding-right: 20px;
            font-weight: 500;
        }

        .dollar-add {
            @extend .dollar;
            padding: 30px 15px;
        }

        .dollar-1 {
            @extend .dollar;
            color: var(--golden);
            font-weight: 600;
            font-size: 21px;
        }
    }

}

.button-back {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-top: 0;
    width: 100%;


    @media screen and (max-width:768px) {
        padding-right: 9px !important;
        width: 100%;
    }

    &.new-add-button {
        @media screen and (max-width:768px) {
            text-align: center;
        }
    }

    .back-text {
        font-size: 18px;
        color: var(--grey);
        font-weight: 600;
        background-color: transparent;
        box-shadow: none;
        border: 0;
        cursor: pointer;



    }

    @media screen and (max-width:992px) {
        padding: 0 8px;
    }
}

.details-user-form {

    .details {
        font-size: 12px;
        margin-bottom: 0;
        padding: 10px 10px 20px;
        color: var(--grey);
        letter-spacing: 2px;
    }

    .input-group-text {
        height: 48px;
        padding: 10px 7px;

        img {
            width: 20px;
        }
    }

    .address-details {
        font-size: 12px;
        color: var(--grey);
        letter-spacing: 2px;
        padding: 30px 10px 20px;

        span {
            font-weight: 600;
        }
    }

    .address-details-1 {
        padding: 0;
        font-size: 12px;
        color: var(--grey);
        letter-spacing: 2px;
        padding: 7px 10px 6px;
    }
}

.list-bil {
    margin: 0;
    padding-top: 10px;
}

.form-membership {
    width: 100%;
    max-width: 350px;
    font-size: 13px;
    color: rgb(19 19 163);
}

.outer-form {

    .bill-address {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
    }

    .form-select {
        position: relative;
        background-image: url('../../public/images/arrows.svg');
        background-size: 20px 50px;

    }
}

// .policy-check {
//     // max-width: 1100px;
//     // width: 100%;
//     margin: 0 auto;
//     padding: 40px 40px 40px;
//     border-top: 1px solid var(--grey);

//     @media screen and (max-width:992px) {
//         padding: 40px 20px;
//     }

//     .container {
//         @media screen and (min-width : 1200px) {
//             max-width: 1340px;
//             width: 100%;
//         }
//     }

//     // .outer-event-policy {
//     //     border: 1px solid #cacdcf;
//     //     padding: 32px 20px;
//     //     display: flex;
//     //     align-items: center;
//     //     justify-content: space-between;
//     //     margin-bottom: 20px;

//     //     @media screen and (max-width :992px) {
//     //         flex-direction: column;
//     //         justify-content: center;
//     //         text-align: center;
//     //     }

//     //     h4 {
//     //         font-size: 18px;
//     //         color: var(--blue-grey);
//     //         font-weight: 600;
//     //         margin-bottom: 0;
//     //         line-height: 30px;
//     //     }


//     // }
// }

// Log in from css start
.login-height {
    min-height: calc(100vh - 70px - 464px);
}

.log-in-from {
    // margin: 65px 0;
    padding: 85px 0;
    // border-top: 1px solid #bdb8b4 !important;
    // margin-top: 13px;
}

.log-in-form {
    max-width: 470px;
    width: 100%;
    margin: 0 auto;

    .new-password {
        font-size: 19px;
        margin-bottom: 24px;
        font-weight: 500;
    }

    .log-account-text {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 1px;
        padding-bottom: 10px;
    }

    .account-text {
        text-align: center;
        margin-top: 20px;
        font-size: 16px;
        color: var(--blue-grey);
        font-weight: 500;

        .get-text {
            color: var(--golden);
        }
    }

    .forgot-text {
        text-align: center;
        display: block;
        font-size: 16px;
        color: var(--golden);
        font-weight: 500;
    }

    .reset-password-change {
        font-size: 16px;
        color: var(--grey);
        text-align: center;
        margin-bottom: 0;
        padding: 10px 0 20px;
        font-weight: 500;
    }


}

.login-button {
    margin-top: 20px;
}

@media screen and (max-width:768px) {
    .login-button {
        width: 100%;
        padding: 12px !important;
        margin: 0;
        margin-bottom: 20px;
    }
}

// Log in from css end

// carry responsibly css start

.carry {
    background-image: url('../../public/images/event-2.jpg');
}

.carry-responsibly-user {
    // max-width: 1100px;
    // width: 100%;
    margin: 40px auto;
    font-family: 'Corbert';
    padding: 0 40px;

    @media screen and (max-width:992px) {
        padding: 0 0px;
    }

    .carry-user-gun {
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
    }

    .container {
        @media screen and (min-width : 1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    .carry-title {
        .carry-heading {
            font-family: 'dashiellfine-medium';
            text-transform: capitalize;
            font-size: 30px;
            font-weight: 600;
            letter-spacing: 1.4;

            @media screen and (max-width:992px) {
                font-size: 23px;
            }
        }

        p {
            font-size: 18px;
            padding-top: 10px;
            color: var(--grey);
            line-height: 25px;
        }

        ul {
            padding: 0;
            list-style: none;
            border-left: 7px solid var(--golden);
            margin: 20px 0 10px;

            li {
                padding-left: 10px;
                font-size: 21px;
                font-weight: 600;
                color: var(--golden);
                font-family: 'dashiellfine-medium';
                line-height: 31px;
            }
        }

        .line-image {
            margin: 70px 0 90px;
        }
    }

    .card-number {
        margin-top: 20px;
        padding: 0 15px;

        .number {
            font-size: 14px;
            font-weight: 600;
        }

        .card-number-text {
            font-size: 1.1rem;
            color: var(--grey);
            line-height: 30px;
        }
    }
}

// carry responsibly css start

// blog card css start
.blog-card {
    // max-width: 1100px;
    // width: 100%;
    margin: 50px auto;
    padding: 0 40px;

    @media screen and (max-width:992px) {
        padding: 0 0;
        margin: 10px auto;
    }

    .container {
        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    .blog-bear-heading {
        font-size: 32px;
        font-weight: 600;
        padding: 10px 10px 15px;
        word-spacing: 3px;

    }

    .first-blag-outer {
        text-align: center;
        color: var(--black);

        .card {
            border: 0;
            border-radius: 0px;

            .blog-image {
                overflow: hidden;

                .blog-gun-user {
                    width: 100%;
                    height: 450px;
                    object-fit: cover;

                    @media screen and (max-width:992px) {
                        height: 100%;
                    }
                }
            }


            .card-body {
                max-width: 700px;
                width: 100%;
                margin: 0 auto 30px;

                @media screen and (max-width :992px) {
                    text-align: left;
                    padding: 16px 0;
                }


                .card-title {
                    font-size: 20px;
                    font-weight: 600;
                }

                .card-text {
                    font-size: 13px;
                    color: var(--grey);
                }

                .card-sub-title {
                    font-size: 17px;
                    line-height: 27px;
                    color: var(--grey);
                }

            }

            transform: scale(1);
            overflow: hidden;
            transition: all 0.5s ease-in-out;

            &:hover img {
                transform: scale(1.1);
                transition: all 0.5s ease-in-out;
                overflow: hidden;
            }

            &:hover .card-title {
                text-decoration: underline;
            }
        }


    }

    .page-linking {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;

        list-style: none;

        li {
            font-family: 'Corbert';
            padding: 0 10px;

            a {
                color: rgb(18 18 18);
                font-size: 15px;

            }

            &.active {
                border-bottom: 1px solid rgb(18 18 18);
            }
        }


    }
}

// blog card css end

// right-to-bear-personal css start

.personal-gun {
    padding: 0px 10px 30px;

    @media screen and (max-width:992px) {
        padding: 0px 0px 30px;
    }

    .container {


        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;

        }
    }

    .insurance-gun-user {
        max-width: 780px;
        width: 100%;
        margin: 0 auto;
        display: block;

        @media screen and (max-width : 768px) {
            padding: 0;
        }

        img {
            width: 100%;
            height: 270px;
            object-fit: cover;

            @media screen and (max-width:768px) {
                height: 110px;
            }
        }
    }

    .handgun {
        max-width: 770px;
        width: 100%;
        margin: 75px auto 30px;

        @media screen and (max-width:992px) {
            margin: 25px auto 30px;
        }

        .handgun-title {
            font-size: 35px;
            font-weight: 600;
            color: var(--black);
            word-spacing: 2px;

            @media screen and (max-width:768px) {
                font-size: 25px;
                padding-top: 14px;
            }
        }

        .handgun-sub-title {
            font-size: 13px;
            word-spacing: 2px;
            color: var(--grey);
        }
    }

    .protection {
        max-width: 780px;
        width: 100%;
        margin: 0 auto;

        .share-link {
            img {
                width: 15px;
                margin-right: 10px;
            }

            color: var(--grey);
            font-size: 15px;
        }

        .owning-text {
            font-size: 16px;
            color: #504e4e;
            font-weight: 600;
            line-height: 30px;
            padding-top: 20px;
        }

        .owning-text-1 {
            font-style: italic;
            @extend .owning-text;
            color: rgb(154, 154, 160);
            font-weight: 500;
        }

        .text-change {
            font-weight: 400 !important;
            font-size: 16px !important;
            color: rgb(98, 98, 104) !important;
        }

        .gun-link-firearm-1 {
            font-size: 17px;
            color: rgb(127 124 124);
            font-weight: 400;
            line-height: 30px;
            padding: 10px 0;

            ul {
                padding: 0;
                padding-left: 18px;
            }
        }

        .gun-link-firearm {
            font-size: 17px;
            color: rgb(127 124 124);
            font-weight: 400;
            line-height: 30px;
            padding: 10px 0;

            a {
                color: #423333;
                text-decoration: underline;
                font-size: 16px;
                font-family: 'Condensed';
                font-weight: 600;

                &:hover {
                    text-decoration-thickness: 0.1rem;
                }
            }

            ul {
                list-style: none;
                padding: 0;
                margin-bottom: 0;


                &.dote {
                    list-style: disc !important;
                    padding-left: 25px;
                }
            }

            ol {
                li {
                    padding: 5px 0;

                    a {
                        font-weight: 600;
                    }
                }
            }
        }

        .blog-back {
            text-align: center;

            a {
                font-size: 20px;
                color: var(--black);

                .fa {
                    margin-right: 10px;
                    font-size: 20px;
                }


            }

            // &:hover .fa {
            //     font-size: 22px;
            // }
        }
    }

}

// right-to-bear-personal end

// company vision  css start

.speak {
    background-image: url('../../public/images/hero-2.jpg');

}

.american {
    padding: 45px 0;

    .american-title {
        h3 {
            font-size: 18px;
            font-weight: 600;
            color: var(--golden);
            letter-spacing: 2px;
        }

        h4 {
            font-size: 30px;
            padding-top: 10px;
            font-weight: 600;
            font-family: 'dashiellfine-medium';
            line-height: 30px;
            color: var(--black);
            letter-spacing: 1.2px;

            @media screen and (max-width:992px) {
                font-size: 23px;
                // line-height: 40px;
            }
        }
    }
}

// company vision css end

// support css start

.box-card-price {
    padding: 40px 190px 20px;
    font-family: 'Corbert';

    @media screen and (max-width:992px) {
        padding: 40px 0px 20px;
    }
}

.first-card-price {
    border: 1px solid rgb(219, 219, 219);
    border-radius: 5px;
    // padding: 13px;
    height: 100%;

    .call {
        font-size: 15px;
        font-weight: 600;
        // font-family: 'Corbert';
        border-bottom: 1px solid rgb(219, 219, 219);
        padding: 20px 13px;
        margin-bottom: 0;
    }

    .service {
        padding: 13px 13px 0;

        h3 {
            font-size: 18px;
            font-weight: 700;
            color: #7b858c;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            color: var(--golden);
            font-family: 'Corbert';

            a {
                color: var(--golden);
            }
        }
    }
}

// support css end

// affiliate-banner css start

.affiliate-banner {
    background-image: url('../../public/images/affiliate.jpg');
    padding: 185px 0;
}

.details-net {
    padding: 45px 0px 10px;

    .details-net-text {


        h3 {
            font-size: 30px;
            color: var(--black);
            font-family: 'dashiellfine-medium';
            font-weight: 600;
            letter-spacing: 1px;
            padding-bottom: 20px;
        }

        p {
            font-size: 17px;
            line-height: 30px;
            font-family: 'Corbert';
            color: #7b858c;
        }
    }


}

.line-image {
    margin: 100px 0 90px;

    .container {
        @media screen and (min-width:1200px) {
            max-width: 1340px;
            width: 100%;
        }
    }
}

.qualifying {
    .quint-heading {
        font-size: 19px;
        font-family: 'dashiellfine-medium';
        font-weight: 600;
        letter-spacing: 1px;
        padding-bottom: 20px;
    }

    .quint-2 {
        padding-top: 80px;
        padding-bottom: 0;
        color: rgba(18, 18, 18, 1);
    }

    .sites {

        img {
            width: 13px;
            margin: 20px 0;

        }

        p {
            font-size: 16px;
            color: var(--golden);
            margin: 0;
        }
    }
}

// affiliate-banner css end

// Terms and conditions css start

.terms-and-conditions {
    .conditions {
        .title {
            padding: 40px 0 0px;
            font-size: 1.1rem;
            color: #7b858c;
            line-height: 30px;
            margin-bottom: 0;

            span {
                font-weight: 600;
                color: #504e4e;
            }

            .changes {
                color: #7b858c;
                font-weight: 500;
                padding-left: 50px;
            }

            a {
                color: #7b858c;

            }
        }

        .title-name {
            font-size: 15px;
            font-weight: 600;
            margin: 50px 0 0;
        }
    }
}

// Terms and conditions css end

.instructor {
    background-image: url('../../public/images/instructor-portal.jpg');
    padding: 190px 0;
}

.coming-soon {
    text-align: center;
    padding: 20px 0;

    h2 {
        font-size: 40px;
        color: #000;
        font-family: 'dashiellfine-medium';
    }
}

.alert_inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    display: flex;
    padding: 8px 0;
    align-items: center;
    justify-content: center;

    @media screen and (max-width : 768px) {
        justify-content: flex-start;
    }

    color: #fff;
    cursor: pointer;

    &.error-background {
        background-color: #D9534F;
    }

    &.info-background {
        background-color: #3498DB;
    }

    span {
        font-size: 20px;
        letter-spacing: 1px;
        flex: 1;
        text-align: center;
        padding-left: 10px;

        @media screen and (max-width:768px) {
            font-size: 16px !important;
            text-align: left;
            padding-right: 30px;
        }
    }

    .close__alert {
        color: #fff;
        text-decoration: underline;
        padding-right: 10px;
    }
}

.option-user {
    padding: 0px 30px !important;

    @media screen and (max-width:768px) {
        padding: 30px 20px !important;
    }
}

.plan-number {

    li {
        font-size: 14px;

        a {

            color: #B6632F;

            .fa {
                transform: translateX(10px);
                color: #7B858C;
            }
        }
    }
}

.account-details {
    @media screen and (max-width: 992px) {
        margin: 10px 0 15px;
    }
}

.account-login {
    padding: 3.8rem 2.2rem;
    min-height: calc(100vh - 12px - 70px);


    &.account-new {
        padding: 3.8rem 0rem 0;
    }

    .card-user-id-number {
        padding: 0rem 2.5rem;

        @media screen and (max-width : 768px) {
            padding: 1.8rem 0.2rem 0;

        }
    }

    @media screen and (max-width : 768px) {
        padding: 3.8rem 0.5rem 0;
    }

    .account-log-out {
        margin-bottom: 4rem;
    }


    .link-account {
        color: var(--golden);
        font-size: 14px;
        display: block;

        &:hover {
            color: var(--black);
        }

        .fa {
            padding-right: 6px;
        }
    }

    .account-heading {
        font-size: 20px;
        font-weight: 600;
        color: var(--black);
    }

    .account-sub-heading {
        font-size: 18px;
        color: var(--grey);
    }

    .card-table {
        padding: 10px 0px 0;
        border-radius: 0;
        width: 100%;


        @media screen and (max-width : 992px) {
            overflow-x: scroll;
        }

        .table-list {

            thead {

                @media screen and (max-width : 768px) {
                    display: none;
                    position: absolute;
                }

                tr {
                    th {
                        padding: 10px 0px 10px 20px;
                        border-bottom: 1px solid var(--light-blue-400);
                        font-size: 14px;
                        font-weight: 300;
                        color: var(--blue-grey);
                        white-space: nowrap;

                        &.total-number {
                            text-align: right;
                            padding-right: 20px;

                        }

                    }
                }

            }

            &.product {


                thead {
                    tr {
                        th {
                            text-align: right;
                        }
                    }
                }

                tbody {
                    tr {
                        border-bottom: 1px solid var(--light-blue-400);

                        td {
                            // padding: 20px 0px 20px 20px;
                            // white-space: nowrap;
                            text-align: right;
                            vertical-align: baseline;

                            @media screen and (max-width :768px) {
                                padding-right: 20px;

                            }

                            &:nth-child(5) {
                                padding-right: 20px;

                            }

                            @media screen and (max-width : 768px) {

                                &:nth-child(1):before {
                                    content: "PRODUCT";
                                    transform: translateY(-32px);
                                    font-weight: 500;
                                }

                                &:nth-child(2):before {
                                    content: "SKU";
                                    font-weight: 500;
                                }

                                &:nth-child(3):before {
                                    content: "PRICE";
                                    // transform: translate(-27px, 10px);
                                    font-weight: 500;
                                }

                                &:nth-child(4):before {
                                    content: "QUANTITY";
                                    font-weight: 500;
                                }

                                &:nth-child(5):before {
                                    content: "TOTAL";
                                    font-weight: 500;
                                }
                            }

                            &::before {
                                @media screen and (max-width : 768px) {

                                    float: left;

                                }
                            }

                            .order-id-children {
                                text-align: left;

                                @media screen and (max-width : 768px) {
                                    text-align: right;
                                }
                            }

                        }
                    }
                }

                ul {
                    list-style: none;
                    padding: 0;


                    li {
                        &.minor {
                            padding-bottom: 15px;

                            @media screen and (max-width:768px) {
                                padding-bottom: 5px;

                            }

                            a {
                                font-size: 16px;
                                color: var(--golden);
                                // padding-left: 100px;
                                margin: 30px 0;

                                @media screen and (max-width : 992px) {
                                    padding-left: 0;
                                }
                            }
                        }

                        color: ar(--light-blue-100);
                        font-size: 16px;

                        &.fulfilled {
                            border: 1px solid var(--light-blue-100);
                            display: inline-block;
                            padding: 7px 10px;
                            margin: 10px 0 0;

                        }

                        &.child-total {
                            font-size: 23px;
                            font-weight: 600;
                        }
                    }

                }

                tfoot {

                    .total-list-price {
                        position: relative;
                        padding-top: 20px;

                        .amount-list {
                            text-align: right;
                            padding-right: 20px;
                            position: absolute;
                            top: 20px;
                            right: 0;
                            width: 100%;
                        }
                    }
                }

            }

            tbody {
                tr {
                    border-bottom: 1px solid var(--light-blue-400);

                    td {
                        padding: 20px 0px 20px 20px;
                        white-space: nowrap;

                        @media screen and (max-width:768px) {
                            padding: 5px 15px 20px !important;
                        }

                        input {
                            border: 1px solid var(--light-blue-400);
                            cursor: pointer;
                            padding: 8px 6px;
                            color: var(--golden);

                        }

                        &.table-date-list {
                            text-align: right;
                            padding-right: 20px;

                            @media screen and (max-width : 768px) {
                                // text-align: left;
                                padding-right: 20px;
                                padding-bottom: 10px !important;
                            }
                        }

                        @media screen and (max-width : 768px) {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            text-align: right;
                            padding-right: 20px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            font-weight: 600;
                            font-size: 17px;
                        }

                        @media screen and (max-width : 768px) {

                            &:nth-child(1):before {
                                content: "ORDER";
                                font-weight: 500;

                            }

                            &:nth-child(2):before {
                                content: "DATE";
                                font-weight: 500;
                            }

                            &:nth-child(3):before {
                                content: "PAYMENT STATUS";
                                font-weight: 500;
                            }

                            &:nth-child(4):before {
                                content: "PAYMENT STATUS";
                                font-weight: 500;
                            }

                            &:nth-child(5):before {
                                content: "TOTAL";
                                font-weight: 500;
                            }
                        }

                        &::before {
                            @media screen and (max-width : 768px) {

                                float: left;

                            }
                        }

                    }
                }
            }


        }

        // body style

        body {
            background: #9BC86A;
            font: 400 14px 'Calibri', 'Arial';
            padding: 20px;
        }

        blockquote {
            color: white;
            text-align: center;
        }



    }

    .card-list {
        height: 100%;

        .list-group {
            list-style: none;
            padding: 0px 10px 10px;

            li {
                padding: 5px 0 0;


                &.link {
                    padding: 13px 0 !important;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    a {
                        color: var(--golden);
                        font-size: 17px;
                        font-weight: 600;
                        width: 100%;
                    }

                    .fa {
                        color: var(--golden);
                    }

                }

                &.dollar {
                    font-size: 50px;
                    color: var(--blue-grey);
                    font-family: 'Condensed';
                    font-weight: 500;
                    margin-bottom: 0;

                }

                &.next {
                    color: #969596;
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 5px
                }

                &.pay {
                    font-size: 19px;
                    color: var(--blue-grey);
                    font-family: 'Condensed';
                }

                &.dollar-list {
                    display: flex;
                    align-items: center;
                    padding: 25px 10px;

                    .web-image {
                        width: 65px;
                        height: 65px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        // overflow: hidden;

                        img {
                            width: 100%;
                            opacity: 0.5;
                            margin-right: 20px;

                        }
                    }

                    p {
                        font-size: 15px;
                    }

                    h5 {
                        font-size: 17px;
                        font-weight: 600;
                        color: var(--blue-grey);
                        letter-spacing: 1px;
                    }

                }
            }
        }
    }

    .t-shirt {
        margin: 50px 0px 0px 0;
        border: 1px solid var(--light-blue-400);
    }

    .t-shirt-heading {
        margin-top: 120px;
        padding: 0 80px;
        min-height: 81%;



        @media screen and (max-width : 992px) {
            padding: 0 20px;
            margin-top: 30px;
        }
    }

    .user-card {
        max-width: 1050px;
        width: 100%;
        margin: 0 auto;
        border-radius: 0;

        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // background-color: transparent;
            background-color: var(--blue-grey);
            padding: 20px;
            border-radius: 0;

            @media screen and (max-width : 768px) {
                padding: 20px 5px;
                align-items: flex-start
            }

            h4 {
                margin-bottom: 0;
                font-size: 17px;
                color: var(--white);
                text-transform: uppercase;
                line-height: 1.4;

                &.date-select {
                    display: flex;
                }
            }
        }

        .coverage-yearly {
            padding: 20px;

            @media screen and (max-width : 992px) {
                padding-bottom: 0;

            }

            .logo-cover {
                display: flex;

                @media screen and (max-width: 768px) {
                    // align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                img {
                    width: 100px;
                    opacity: 0.5;
                    margin-right: 20px;

                    @media screen and (max-width: 768px) {
                        width: 70px;
                        // margin-right: 0;
                        margin: 0 auto;
                    }
                }

                h5 {
                    font-size: 19px;
                    font-weight: 600;
                    padding-top: 20px;
                    color: var(--black);
                    text-align: center;

                    @media screen and (max-width :768px) {
                        padding-top: 0;
                    }
                }

                p {
                    color: var(--grey);
                    font-size: 23px;
                    font-weight: 500;
                    margin-bottom: 0;
                }
            }

            h5 {
                font-size: 19px;
                font-weight: 600;
                padding-top: 20px;
                color: var(--black);
            }

            .user-list-gun {
                list-style: none;
                padding: 15px 0;

                @media screen and (max-width : 992px) {
                    margin-bottom: 0;
                }

                li {
                    padding: 13px 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid var(--light-blue-400);
                    color: var(--grey);

                    span {
                        font-size: 17px;
                        color: var(--grey);
                    }
                }

                .click-button {
                    margin-top: 20px;
                    text-align: right;

                    .view {
                        background-color: transparent;
                        box-shadow: none;
                        color: var(--golden);
                        font-size: 15px;
                        font-weight: 500;
                        border: 1px solid transparent;
                    }
                }
            }

            .additional-heading {
                font-size: 19px;
                font-weight: 600;
                padding-top: 20px;
                color: var(--golden);
            }

            .additional-menu-list {
                padding: 0;
                list-style: none;

                li {
                    padding: 2px 0;
                    font-size: 16px;
                    color: var(--blue-grey);
                }

                .additional-heading-2 {
                    font-size: 22px;
                    font-weight: 600;
                    padding-top: 20px;
                    color: var(--golden);
                }
            }
        }
    }

    .member-doc {
        max-width: 1050px;
        width: 100%;
        margin: 50px auto 0px;

        .mem-doc {
            list-style: none;
            padding: 0;

            li {
                font-size: 23px;
                color: var(--black);
                font-weight: 600;
                border-bottom: 1px solid var(--light-blue-400);
                padding-bottom: 10px;

                &.font {
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-right: 10px;

                    a {
                        color: var(--golden);
                    }
                }
            }
        }
    }
}

.js-youtube {
    width: 100%;
    height: 600px;
    // position: relative;

    @media screen and (max-width : 768px) {
        height: 200px;
    }
}

.tabs-bills {
    .nav-tabs {

        border-bottom: 1px solid var(--light-blue-400);

        .nav-link {
            border-bottom: 2px solid transparent;
            color: var(--black);
            letter-spacing: 1px;
            font-weight: 500;
            font-size: 18px;

            @media screen and (max-width :512px) {
                font-size: 15px;
            }

            &.active {
                border-color: transparent;
                background-color: transparent;
                border-bottom: 2px solid var(--golden);
                color: var(--golden);
            }

            &:hover {
                border-color: transparent;
                border-bottom: 2px solid var(--golden);

            }
        }
    }
}

.pane-bill {
    .up-coming-bill {
        padding: 20px 0 0;
        font-size: 17px;
        font-weight: 600;
        color: var(--black);
    }

    .bills-card {
        border-radius: 0px;
        overflow: auto;

        @media screen and (max-width : 992px) {
            margin-bottom: 40px;
        }

        .amount-card {
            white-space: nowrap;

            thead {
                background-color: var(--light-blue-400);

                th {
                    padding: 17px 15px;
                    border-bottom: 1px solid var(--light-blue-100);

                    @media screen and (max-width : 768px) {
                        padding: 17px 45px;
                    }
                }
            }

            tbody {

                tr {
                    border-bottom: 1px solid var(--light-blue-100);
                }

                tr:last-child {
                    border-bottom: 0px solid var(--light-blue-100);
                }

                td {
                    padding: 17px 15px !important;
                    font-size: 16px;

                    @media screen and (max-width : 768px) {
                        padding: 17px 45px;
                    }
                }
            }

            .details-list2 {
                color: var(--golden);
                font-size: 15px;
                font-weight: 600;
                background-color: transparent;
                border: 0px solid;
                box-shadow: none;
            }
        }
    }

    .history {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;

        @media screen and (max-width : 568px) {
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 20px;
        }

        button {
            box-shadow: none;
            background-color: transparent;
            color: var(--golden);
            font-size: 16px;
            border: transparent;
        }
    }
}

.safety {
    margin: 10px 0 50px;

    .shooting {
        margin-bottom: 60px;

        img {
            max-width: 900px;
            height: 450px;
            object-fit: cover;
            width: 100%;

            @media screen and (max-width : 992px) {
                height: 200px;
            }
        }

        @media screen and (max-width : 992px) {
            margin-bottom: 30px;

        }

        &.mt-img {
            @media screen and (max-width : 992px) {
                margin-top: 20px;

            }
        }
    }


}

.safety-text {
    padding: 40px 70px;
    margin-bottom: 0 auto;

    @media screen and (max-width:992px) {
        padding: 0 10px;
    }

    p {
        font-size: 22px;
        color: var(--grey);
        padding: 10px 0;
    }
}


.user-details {
    padding: 3.8rem 3rem;

    @media screen and (max-width : 768px) {
        padding: 0;
    }

    .link-profile {
        border-bottom: 1px solid #dee2e6;

        ul {
            padding: 0;
            list-style: none;
            margin-bottom: 0;
            position: relative;
            padding-left: 35px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 150px;
                height: 4px;
                background-color: var(--golden);
            }

            li {
                padding-bottom: 5px;

                a {
                    font-size: 17px;
                    text-transform: uppercase;
                    color: var(--golden);
                }
            }
        }
    }
}

.dropdown {
    .dropdown-menu {
        margin-top: 50px !important;
        padding: 0;
        border-radius: 0;

        .dropdown-item {
            font-size: 18px;
            font-weight: 600;
            color: var(--golden);
            padding: 13px 13px 13px 10px;
            letter-spacing: 1px;

            &.border-list {
                border-bottom: 1px solid #d7d6d680;
            }

            &:hover {
                background-color: var(--golden);
                color: var(--white);

            }


            &.active_menu {
                background-color: var(--golden);
                color: var(--white);
            }
        }
    }
}

.primary-heading {
    padding-top: 30px;
    padding-bottom: 20px;
    font-size: 20px;
    color: var(--golden);
    font-weight: 600;
}

.size-modal {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    padding: 10px;
}

.billing-list {

    .billing-heading {
        border-bottom: transparent;
        font-size: 14px;
        font-weight: 600;
        color: var(--black);
    }

    .people {
        font-size: 12px;
        margin-left: 10px;
        letter-spacing: 3px;
        color: rgb(165, 156, 156);
    }

    .billing-body {
        padding-top: 0;
        padding-bottom: 0;
    }

    .billing-footer {
        justify-content: flex-start;
        border-top: transparent;
        padding-top: 0;
        padding-bottom: 10px;
    }
}

.return-details {
    // max-width: 1100px;
    // width: 100%;
    margin: 0 auto;

    .return-account {
        text-decoration: none;
        font-size: 16px;
        text-align: center;
        margin: 0;
        padding: 0 0 10px 0;
        color: var(--golden);
    }
}

.profile {
    font-size: 20px;
    text-align: center;
    color: var(--black);
    font-weight: 600;
    margin: 0;
    padding: 0 0 10px 0;

    &.pro-changes {
        text-align: left;
        padding: 10px 0;
        padding-left: 16px;

        @media screen and (max-width:992px) {
            padding-left: 0px;
        }
    }
}

.unite {
    padding: 10px;
    margin: 0px 0 23px;


    &:focus {
        outline: 0;
        box-shadow: none;
        outline: 2px solid #f1c7ac;
        border-radius: 5px;
        border-color: transparent;
    }
}

.default-user {
    text-align: center;
    margin-top: 60px;



    .col-list {
        display: flex;
        flex-wrap: wrap;
        max-width: 350px;
        width: 100%;
        margin: 0 auto;
    }


    .default-card {
        padding: 0px 20px;
        margin: 5px;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;

        // .card-user-id-text {
        //     padding: 50px 0 00px;
        // }


        .default-body {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: auto;

            h2 {
                font-size: 18px;
                color: var(--black);
                font-weight: 600;
                padding-top: 20px;
                margin-bottom: 0;
            }

            p {
                line-height: 30px;
                font-size: 18px;
                color: var(--grey);
                margin: 0;
                padding: 5px 0;
            }

            .default-bottom {
                margin-top: auto;
            }
        }
    }



}

.right-video {
    position: relative;

    .video-iframe {
        width: 100%;
        height: 600px;

        @media screen and (max-width: 768px) {
            height: 100%;
        }

    }
}

.check-focus {
    &:focus {
        // box-shadow: none;
        // outline: 2px solid #b6632f;
        border-radius: 0;
        box-shadow: 0 0 0 0.1rem #f1c7ac;
    }
}

.last-accordion {
    border-bottom: 1px solid transparent !important;

}

.last-item-accordion {
    margin-bottom: 50px;

    @media screen and (max-width : 768px) {
        margin-bottom: 20px;
    }
}

.owl-nav {
    text-align: center;

    .owl-prev {
        width: 40px;
        height: 40px;

        span {
            font-size: 60px;
        }
    }
}

.owl-next {
    span {
        font-size: 60px;
    }
}

.delete-text {
    h3 {
        font-size: 22px;
        color: var(--black);
        margin: 0;
        letter-spacing: 1px;
    }

    p {
        font-size: 18px;
        margin: 0;
        color: var(--black);
    }
}

.cancel-button {
    border-top: 1px solid transparent;

    .cancel {
        background-color: transparent;
        box-shadow: none;
        padding: 5px 20px;
        border: 1px solid var(--golden);
        border-radius: 5px;
        color: var(--golden);
        font-size: 20px;

        &.ok {
            border: 1px solid var(--golden) !important;
            color: var(--golden);
        }

        &:hover {
            background-color: var(--golden);
            color: var(--white);
        }
    }
}

.card-payment {
    padding: 20px;
    margin-top: 10px;
    // margin-top: 60px;
    margin-left: 120px;

    @media screen and (max-width : 992px) {
        margin-left: 0;
    }

    h5 {
        font-size: 18px;
        padding-bottom: 10px;
    }
}

.border-top {
    margin-top: 0px;
}

.items-plan {
    background-color: #f5f5f5;
    height: 100%;
    border-left: 1px solid #d6d5d5;
    padding: 50px;

    &.item-plan-new {
        padding: 10px 50px;
        background-color: transparent;

        @media screen and (min-width : 1199px) {
            padding: 10px 100px;
        }

        @media screen and (max-width : 992px) {
            padding: 10px 0px;
        }
    }

    &.items-border-left-remove {
        border-left: 0;
    }

    @media screen and (max-width : 992px) {
        padding: 20px 26px 20px 20px;
        border-left: 0;
    }

    .table {

        tbody {
            tr {
                td {
                    border-bottom: transparent;
                    // padding-left: 0;
                    // padding-right: 0;

                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }


    }

    .plan {

        &.plan-2 {
            display: flex;
        }

        .plan-box-camera {
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            // overflow: hidden;
            background-color: #e6e6e6;
            border: 1px solid #d6d5d5;
            margin-right: 10px;
            border-radius: 15px;
            // opacity: 0.8;
            position: relative;

            .fa {
                font-size: 25px;
                color: #b3b0b0;
            }

            &::after {
                content: '1';
                position: absolute;
                right: -6px;
                top: -7px;
                width: 20px;
                height: 20px;
                background-color: var(--grey);
                border-radius: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--white);
                font-size: 12px;
            }
        }
    }
}

.stripe {
    margin-left: 120px;

    @media screen and (max-width : 992px) {
        margin-left: 0;
    }
}

.discount-input {
    padding: 13px 10px;
}

.apply-button {
    background-color: #B6632F;
    border: transparent;
    padding: 13px 12px;
    border-radius: 5px;
    color: var(--white);
    font-weight: 600;
    font-size: 17px;

    &.disabled {
        pointer-events: none !important;
        background-color: #b1b1b1 !important;
    }
}

.remove-coupon {
    font-weight: bold;
    text-decoration: underline;
    color: red;
    margin-left: 1.25rem;
    width: 50%;
    cursor: pointer;
}

.status {
    font-size: 17px;
}

.children-yearly {
    text-align: center;

    .children {
        margin-bottom: 80px;

        @media screen and (max-width:768px) {
            padding: 0 40px;

        }
    }



    .plan-quantity {
        font-size: 14px;
        margin: 0;
        color: var(--input-color);
    }

    h1 {
        font-size: 28px;
        font-weight: 600;
        word-spacing: 2px;
        padding-bottom: 10px;

        @media screen and (max-width:768px) {
            font-size: 23px;
        }
    }

    h5 {
        font-size: 20px;
        color: var(--input-color);
        font-weight: 400;
        padding-bottom: 20px;
    }

    .plan-plus-minus {
        border: 1px solid var(--black);
        display: inline-block;
        padding: 10px 0;
        margin-top: 10px;
        align-items: center;
        justify-content: center;

        button {
            background-color: transparent;
            border: 1px solid transparent;
            padding: 0 15px;
            font-size: 15px;


            .fa {
                color: var(--grey);
            }
        }
    }

    .add-to-cart {
        margin: 20px 0;
    }

    .accordion-materials {
        max-width: 650px;
        width: 100%;
        margin: 0 auto;

        .accordion {
            .accordion-item {
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;

                .accordion-header {
                    .accordion-button {
                        padding: 13px 20px;
                    }

                    .accordion-button:not(.collapsed) {
                        box-shadow: none !important;
                        background-color: transparent !important;
                        color: var(--black);
                    }

                    .accordion-button:focus {
                        background-color: transparent;
                        box-shadow: none;
                    }

                    .accordion-button::after {
                        background-size: 15px;
                    }

                    .accordion-button:not(.collapsed)::after {
                        background-image: url(../../public/images/down.png);
                    }

                }

                .accordion-body {
                    text-align: left;
                    padding: 0px 56px;
                }

            }

        }
    }

    .share-button {
        margin-top: 30px;
        cursor: pointer;


        img {
            width: 20px;
            margin-right: 10px;
        }

        span {
            &:hover {
                border-bottom: 1px solid var(--black);
            }
        }
    }
}

.collection {
    background-color: var(--light-blue-400);
    margin-top: 40px;

    .image-text {
        border-right: 1px solid var(--light-blue-100);
        padding: 60px 65px;

        @media screen and (max-width : 992px) {
            padding: 60px 20px;
            border-right: 0px solid;
        }

        p {
            font-size: 20px;
            color: var(--grey);
            padding-top: 20px;
            margin-bottom: 0;
        }
    }

    .black-image {
        padding: 60px 65px;
        width: 300px;
        height: 250px;
        margin: 0 auto;

        @media screen and (max-width :992px) {
            border-bottom: 1px solid var(--light-blue-100);
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;

            @media screen and (max-width :992px) {
                width: 100px;
                height: 100px;
                margin: 0 auto;
                display: block;
            }
        }
    }
}

.free-shipping {
    padding: 60px;

    @media screen and (max-width:"992px") {
        padding: 20px 0;
    }

    .free-text {
        h4 {
            font-size: 17px;
            color: var(--black);
            font-weight: 600;
        }

        p {
            font-size: 17px;
            color: var(--grey);
        }
    }
}

.also {
    padding: 0 60px;

    @media screen and (max-width : 992px) {
        padding: 0;
    }

    .card_content {
        .card_information {
            background-color: var(--light-blue-400);
            max-width: 600px;
            width: 100%;
            aspect-ratio: 1/1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            .card_heading {
                a {
                    color: var(--black);
                    font-size: 17px;
                    font-weight: 600;
                }
            }

            &:hover a {
                border-bottom: 1px solid var(--black);
            }
        }

        .card_footer {
            padding-top: 20px;

            h4 {
                margin: 0;
                font-size: 20px;
                color: var(--input-color);
            }
        }
    }
}

.border-alert {
    box-shadow: 0px 0px 5px gray;
    outline: 2px solid gray;
}

.card-header {
    background-color: transparent;
}

.video-iframe {
    width: 100%;
    height: 600px;

    @media screen and (max-width : 768px) {
        width: 100%;
        height: 200px;
    }
}

.thank-you {
    padding: 20px 0;
    width: 100%;

    @media screen and (max-width:992px) {
        text-align: center;
    }
}

.details-order {
    margin: 10px 0;
    font-weight: 600;
    font-size: 20px;
}

.information {
    margin-bottom: 0px;
    font-weight: 600;
}

.add-bill {
    font-weight: 600;
}

.summary-order {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 414px;
    width: 100%;
    margin-bottom: 20px;

    @media screen and (max-width : 1199px) {
        max-width: 100%;
    }

    h4 {
        font-size: 20px;
        color: var(--black);
        margin-bottom: 0;

        font-weight: 600;
    }

    p {
        margin-bottom: 0;
        color: #3498DB;
        cursor: pointer;
    }
}

.login-alert {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @media screen and (max-width :768px) {
        align-items: flex-start;
    }
}

.talk-about {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;

    .title-text {
        font-size: 19px;
        color: var(--golden);
        font-family: "Assistant";
        font-weight: 800;
        letter-spacing: 1.2px
    }

    h3 {
        font-size: 30px;
        font-family: 'dashiellfine-medium';
        font-weight: 600;
        color: var(--black);
    }

    p {
        font-size: 17px;
        color: var(--black);
    }
}

.remove-coupon {
    transform: translateY(13px);
    padding-right: 10px;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    text-decoration: none;
}

.close-input {
    display: block;
    text-align: right;
    font-size: 16px;
    font-weight: 600;
    color: red;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.referral-code-css {
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}

.discount {
    position: relative;
    padding-left: 5px !important;

    .discount-remove {
        position: absolute;
        top: 21px;
        right: 20px;
        color: red;
        font-weight: 800;

        &:hover {
            text-decoration: underline;
        }

        &.disable {
            pointer-events: none;
        }
    }
}

.error-mag {
    @media screen and (min-width : 990px) {
        margin-left: 120px;
    }
}

.billing-list-add {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
        margin-bottom: 0;
        font-size: 18px;
        cursor: pointer;
        font-size: 15px;
        color: #3498DB;
        font-weight: 500;
    }
}

.right-to {
    padding: 50px 40px 30px;

    @media screen and (max-width : 992px) {
        padding: 50px 0px 30px;
    }

    .ranked {
        font-size: 40px;
        font-weight: 600;
        color: var(--black);
        margin-bottom: 0;

        @media screen and (max-width : 992px) {
            font-size: 20px;
        }
    }

    img {
        width: 300px;
        display: block;
        margin: 0 auto 30px;
    }

    .review {
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: 400;

        a {
            text-decoration: underline;

        }
    }

    .find-heading {
        font-size: 43px;
        font-weight: 600;
        color: var(--black);
        margin-top: 90px;

        @media screen and (max-width : 992px) {
            font-size: 33px;
        }
    }
}

.event-dote {
    position: relative;
    margin-bottom: 50px;

    &.padding-add {
        padding-bottom: 120px;
        margin-bottom: 100px;
    }

    .loading-dote {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -70px;
    }
}

.Insurance {
    padding: 30px 35px;

    .container {
        @media screen and (min-width : 120px) {
            max-width: 1340px;
            width: 100%;
        }
    }

    h4 {
        font-size: 38px;
        font-weight: 600;
        color: var(--black);
        margin-bottom: 0;
        padding-bottom: 40px;
    }

    h5 {
        @extend h4;
        font-size: 20px;
        padding-bottom: 16px;
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            font-size: 17px;
            padding-bottom: 25px;
            color: var(--grey);

        }
    }
}

.width-cuman {
    width: 100%;

    @media screen and (min-width : 992px) {
        max-width: 414px;
        width: 100%;
    }

    p {
        &.plan {
            text-align: left !important;
        }
    }
}

.payment-check-now {
    width: 100%;

    @media screen and (min-width : 992px) {
        max-width: 440px;
    }
}

.table-text-right {
    text-align: right;

    @media screen and (max-width : 1199px) {
        text-align: right;
    }
}

.link-text {
    color: #0d6efd;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    padding-left: 0;
    text-decoration: underline;
}

.add-m-d {
    display: block;
    text-align: center;
    margin-top: 50px;
}

.experiences-card {
    height: 100%;
    width: 100%;
    margin: 10px auto 0;
    padding: 0 10px 8px;
}

.gd-carousel .react-multiple-carousel__arrow--left {
    left: calc(-3% + 1px) !important;
}

.gd-carousel .react-multiple-carousel__arrow--right {
    right: calc(-3% + 1px) !important;
}

.gd-carousel .react-multiple-carousel__arrow {
    background-color: var(--golden) !important;
    position: absolute;
    z-index: 1 !important;
}

.gd-carousel {
    position: static;
}

.react-multi-carousel-dot-list {
    bottom: -40px;
    position: absolute;
}

.react-multi-carousel-dot button {
    border-color: #1a2a3f !important;
    border-color: var(--golden) !important;
    height: 22px;
    width: 22px;
}

.react-multi-carousel-dot button:hover:active,
.react-multi-carousel-dot--active button {
    background: var(--golden);
}

@media screen and (max-width: 1199px) {
    .gd-carousel .react-multiple-carousel__arrow--left {
        left: calc(-1% + 1px) !important;
    }
}

@media screen and (max-width: 1199px) {
    .gd-carousel .react-multiple-carousel__arrow--right {
        right: calc(-1% + 1px) !important;
    }
}

.r-m {
    display: inline !important;
    text-decoration: underline;
    font-size: 16px !important;
    padding-left: 5px;
}

.p-n {
    align-items: center;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    margin: 10px 0;

    .previous {
        background-color: var(--golden);
        border-radius: 5px;
        color: #fff;
        color: var(--white);
        padding: 10px;
        text-align: center;
    }

    .next-2 {
        background-color: var(--golden);
        border-radius: 5px;
        padding: 10px;
        text-align: center;
        color: #fff;
    }

    .disabled-btn {
        cursor: not-allowed;
        opacity: .5;
        pointer-events: none;
    }
}

.s-p {
    text-align: center;
    margin: 50px 0;

    a {
        background-color: var(--golden);
        padding: 10px;
        border-radius: 5px;
        color: var(--white);
        font-size: 16px;
        font-weight: 500;

    }
}

.details-order {
    @media screen and (min-width : 1199px) {
        padding-left: 100px;
    }
}

.contact-details {
    display: flex;
    column-gap: 70px;

    @media screen and (min-width : 1199px) {
        padding: 0 100px;
    }

    @media screen and (max-width : 992px) {
        flex-direction: column;
    }
}

.continue-btn {
    text-align: center;
    margin-top: 40px;


    button {
        @media screen and (min-width : 992px) {
            margin-left: 20px;
        }
    }
}

.payment-btn-add {
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding-left: 18px;

    @media screen and (max-width : 992px) {
        padding-left: 0px;
        padding: 0;
    }

    @media screen and (max-width : 768px) {
        padding-right: 11px !important;
        padding: 0;
    }

    button {
        @media screen and (max-width : 768px) {
            flex: 1 0 50%;
        }
    }

    a {
        @media screen and (max-width : 768px) {
            flex: 1 0 50%;
        }
    }
}

.pop-list {
    .back-bb {
        background-color: transparent;
        border: 0;
        display: flex;
        font-weight: 500;
        margin-left: 115px;

        @media screen and (max-width : 992px) {
            margin-left: 9px;
        }

        .fa {
            color: #0d6efd;

            font-size: 20px;
            padding-right: 5px;
        }

        span {
            color: #0d6efd;
            font-size: 16px;
            text-decoration: underline;
        }
    }
}

.react-tooltip {
    background-color: red !important;
    max-width: 300px !important;
    width: 100%;
    z-index: 1;
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    position: relative;
    transform: translateY(-20px);
}

.account-user {
    padding: 0 20px;

    @media screen and (max-width : 992px) {
        padding: 0 0px;
    }
}


input[type="date"] {
    color: #ffffff;
    position: relative;
}

input[type="date"]::before {
    color: #98A2B3;
    content: attr(placeholder);
    position: absolute;
}

input[type="date"]:focus,
input[type="date"]:valid {
    color: #101828;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
    content: "" !important;
}

.close-icon {
    position: absolute;
    right: 15px;
    top: 27px;
}

.ps {
    position: relative;

    .input-group-text {
        background-color: transparent !important;
        border: 0;
        cursor: pointer;
        height: 50px;
        padding: 10px 7px;
        position: absolute;
        right: 0;
        top: 0;

        img {
            width: 20px;
        }
    }
}