@font-face {
    font-family: 'Assistant';
    src: url('../../../public/fonts/Assistant-Bold.ttf');
    font-weight: 800;
}

@font-face {
    font-family: 'Assistant';
    src: url('../../../public/fonts/Assistant-Medium.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Assistant';
    src: url('../../../public/fonts/Assistant-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Corbert';
    src: url('../../../public/fonts/Corbert-Condensed-Bold.ttf');
    font-weight: 800;

}

@font-face {
    font-family: 'Condensed';
    src: url('../../../public/fonts/Condensed-Medium.otf');
    font-weight: 500;
}

@font-face {
    font-family: 'Corbert';
    src: url('../../../public/fonts/Corbert-light.otf');
    font-weight: 300;
}

@font-face {
    font-family: 'dashiellfine-medium';
    src: url('../../../public/fonts/yrsa-medium.ttf');
}