:root {
    --white: #fff;
    --black: #000;
    --golden: #b6632f;
    --grey: #121212BF;
    --light-blue-100: #d8dade;
    --light-blue-400: #ececef;
    --blue-grey: #323e48;
    --input-color: #b9b8b8;
    --text-color: #121212;

}

$white: var(--white);
$black: var(--black);
$primary: var(--golden);
$grey : var(--grey);
$light-grey: var(--light-blue-100);
$light-blue-grey: var(--light-blue-400);
$secondary: var(--blue-grey);
$input-color: var(--input-color);
$color-text: var(--text-color);