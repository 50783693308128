.radioBtn {
    // margin-top: 20px;
    padding: 3px;
    width: 100%;

    .radio-input {
        display: none;
    }

    .radio__label {
        font-size: 16px;
        font-weight: 700;
        padding: 14px 45px 14px 14px;
        text-align: left;
        width: 100%;
        border: 1px solid var(--light-blue-400);
        position: relative;
        border-radius: 3px;
        font-family: 'Corbert';

        @media screen and (max-width: 992px) {
            padding: 14px 55px 14px 14px;
        }

        p {
            margin-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            top: 35%;
            right: 20px;
            border: 1px solid var(--light-blue-400);
            border-radius: 50px;
            // z-index: 999;
        }


    }



    .radio-input:checked+.radio__label {
        background-color: #f0e8e3e0;
        border: 1px solid var(--golden);
        position: relative;

    }

    .radio-input:checked+.radio__label::after {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        top: 35%;
        right: 20px;
        width: 18px;
        height: 18px;
        background-color: var(--golden);
        border-radius: 50px;
        border: 1px solid var(--light-blue-400);

    }

}

.form-group {
    display: block;
    margin-bottom: 10px;
    // height: 100%;

    .check-box-list {

        display: none;

    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        pointer-events: none;
    }

    .later-book {
        position: relative;
        cursor: pointer;
        white-space: wrap;
        font-size: 14px;
        margin-top: 20px;
        border: 1px solid var(--light-blue-400);
        width: 100%;
        // height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 13px 50px 13px 13px;
        padding: 13px;
        font-weight: 600;
        font-family: 'Corbert';
        border-radius: 4px;
        // text-align: center;

        &.book-name {
            z-index: 999;
        }

        &::before {
            content: '';
            position: absolute;
            top: 37%;
            right: 20px;
            // width: 16px;
            // height: 16px;
            // border: 1px solid var(--light-blue-400);
            border-radius: 50px;
        }

    }

    .check-box-list:checked+.later-book::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        // width: 15px;
        // height: 15px;
        background-color: var(--golden);
        margin-top: 1px;
        border-radius: 50px;
        // border: 1px solid var(--golden);
    }

    .check-box-list:checked+.later-book::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #f0e8e3e0;
        left: 0;
        top: 0;
        z-index: -1;
        border: 1px solid var(--golden);
        border-radius: 4px;
    }
}

.radio-button {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    border: 1px solid var(--light-blue-400);
    margin-left: 20px;

    .radio-input {
        display: none;
    }

    .radio__label {
        font-size: 17px;
        font-weight: 600;
        padding: 12px 30px;
        color: var(--black);
        font-family: 'Corbert';

        p {
            margin-bottom: 0;

        }


    }

    .radio__label-1 {
        @media screen and (max-width : 568px) {
            padding: 5px 10px;
        }
    }

    .radio-input:checked+.radio__label {
        background-color: var(--golden);
        color: var(--white);
    }
}